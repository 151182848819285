<template>
  <v-app-bar
    v-scroll="onScroll"
    app
    elevate-on-scroll
    height="95"
    color="white"
  >
    <!-- <v-img v-if="$vuetify.breakpoint.mdAndUp"
      alt="Feil Counseling"
      class="mb-2"
      max-width="25%"
      src="/static/feil-logo.png"
    /> -->
    <v-img
      alt="Feil Counseling and Consulting Logo"
      class="mb-2"
      :max-width="$vuetify.breakpoint.mdAndUp ? '25%' : '250'"
      src="/static/feil-logo.png"
      style="cursor:pointer;"
      @click="$router.push({name: 'Home'})"
    />
    <!-- <v-img v-else
      alt="Feil Counseling"
      class="mb-2"
        max-height="42"
      max-width="42"
      src="/static/feil_logosingular.png"
    /> -->
    <v-spacer />
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :active-class="!isScrolling ? 'primary--text' : undefined"
        :to="item.to"
        text
      >
        <span v-text="item.text" />
      </v-btn>
    </v-toolbar-items>
    <!-- <v-app-bar-nav-icon class="pr-11 mr-3" -->
    <v-app-bar-nav-icon
      v-else
      aria-label="Open Navigation Drawer"
      @click="toggleDrawer"
    />
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapMutations } from 'vuex'

  export default {
    data: () => ({
      isScrolling: false
    }),

    computed: {
      items () {
        return [
          {
            'to': '/',
            'text': 'Home'
          },
          {
            'to': '/counseling',
            'text': 'Counseling'
          },
          {
            'to': '/ministry',
            'text': 'Ministry'
          },
          {
            'to': '/consulting',
            'text': 'Consulting'
          },
          {
            'to': '/about',
            'text': 'About'
          },
          {
            'to': '/contact',
            'text': 'Contact'
          }
        ]
      }
    },

    methods: {
      ...mapMutations(['toggleDrawer']),
      onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 25
      }
    }
  }
</script>
